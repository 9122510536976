import { createContext, useState } from 'react';

const AlertContext = createContext({
    message: '',
    severity: '',
    isOpen: false,
    setAlert: () => { },
    onAlertClose: () => { },
});

export const AlertProvider = ({ children }) => {
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [duration, setDuration] = useState(3500);
    const [isOpen, setisOpen] = useState(false);

    const setAlert = (message, severity, durationMilliseconds = 3500) => {
        setMessage(message);
        setSeverity(severity);
        setDuration(durationMilliseconds);
        setisOpen(true);
    };

    const onAlertClose = () => {
        setisOpen(false);
    };

    return (
        <AlertContext.Provider
            value={{
                message,
                severity,
                duration,
                isOpen,
                setAlert,
                onAlertClose,
            }}
        >
            {children}
        </AlertContext.Provider>
    );
};

export default AlertContext;