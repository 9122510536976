import React, { useEffect } from "react";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { Navigate, useSearchParams } from "react-router-dom";
import { InteractionType } from "@azure/msal-browser";
import jwt_decode from "jwt-decode";
import { loginRequest } from "../authConfig";

function AcceptInvite() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    if ((inProgress === 'none') && searchParams.has("token")) {
      const currentAccount = instance.getActiveAccount();
      if (currentAccount) {
        instance.logoutRedirect({
          account: currentAccount,
          postLogoutRedirectUri: window.location.href
        }).then();
      }
    }
  }, [inProgress])

  if (!searchParams.has("token")) {
    return (
      <Navigate to="/" />
    )
  }

  const decoded = jwt_decode(searchParams.get("token"));
  if (!decoded || !decoded['exp'] || (new Date().getTime() > decoded['exp'] * 1000)) {
    return (
      <div>This invite code has expired, please ask for the invite to be resent.</div>
    );
  }

  const acceptInviteLoginRequest = Object.assign({}, loginRequest, {
    authority: process.env.REACT_APP_ACCEPTINVITE_AUTHORITY,
    extraQueryParameters: {
      "client_assertion_type": "urn:ietf:params:oauth:client-assertion-type:jwt-bearer",
      "client_assertion": searchParams.get("token")
    },
    redirectStartPage: "/"
  });

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={acceptInviteLoginRequest}>
      <div>Redirecting to login gateway...</div>
    </MsalAuthenticationTemplate>
  );
}

export default AcceptInvite;
