import React from "react";
import { postRequest } from "./QuoteApiService";

export const quoteExportToExcel = (rec) => {

    console.log("Services > quoteExportToExcel called...");
    return new Promise((resolve, reject) => {
      try {
        postRequest('QuoteExportToExcel/', rec)
        .then((res) => {
          console.log("QuoteExportToExcel > axios res=", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("QuoteExportToExcel > axios err=", err);
          reject("Error in QuoteExportToExcel axios!");
        });
      } catch (error) {
        console.error("in services > QuoteExportToExcel, Err===", error);
      }
    });
  
}

export const multipleQuoteExportToExcel = (rec) => {

  console.log("Services > quoteExportToExcelMultiple called...");
  return new Promise((resolve, reject) => {
    try {
      postRequest('QuoteExportToExcelMultiple/', rec)
      .then((res) => {
        console.log("QuoteExportToExcelMultiple > axios res=", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("QuoteExportToExcelMultiple > axios err=", err);
        reject("Error in QuoteExportToExcelMultiple axios!");
      });
    } catch (error) {
      console.error("in services > QuoteExportToExcelMultiple, Err===", error);
    }
  });

}