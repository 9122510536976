import { deleteRequest, getRequest, postRequest } from "./QuoteApiService";

export const getUsers = () => {
    return getRequest('GetUsers?sort=name');
}

export const createUser = (newUser) => {
    return postRequest('CreateUser', newUser);
};

export const removeUser = (userId) => {
    return deleteRequest(`RemoveUser/${userId}`);
};

export const getRoles = () => {
    return getRequest('GetRoles');
}

export const resendUserInvite = (username) => {
    return postRequest('ResendUserInvite', { username });
}
