import { postRequest } from "./QuoteApiService";

export const getQuoteAddresses = async (quoteId, body) => {
  try {
    const res = await postRequest('GetQuoteAddresses/'+quoteId, body);
    console.log("GetQuoteAddresses > axios res=", res);
    return res;
  } catch (error) {
    console.error("in services > GetQuoteAddresses, Err===", error);
    return error;
  }
}
