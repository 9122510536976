import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { arePermissionGranted } from 'services';
import { Navigate } from 'react-router-dom';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Quotes = Loadable(lazy(() => import('pages/Quotes')));
const QuotesUnfiltered = Loadable(lazy(() => import('pages/Quotes')));
const QuoteDetail = Loadable(lazy(() => import('pages/quotesearch/QuoteDetail')));
const Rules = Loadable(lazy(() => import('pages/Rules')));
const PriceLists = Loadable(lazy(() => import('pages/PriceLists/PriceLists')));
const PriceListDetails = Loadable(lazy(() => import('pages/PriceLists/PriceListDetails')));
const Users = Loadable(lazy(() => import('pages/Users')));
const Subcontractors = Loadable(lazy(() => import('pages/Subcontractors')));
const Subcontractor = Loadable(lazy(() => import('pages/Subcontractor')));
const Items = Loadable(lazy(() => import('pages/Items')));
const Item = Loadable(lazy(() => import('pages/Item')));
const CustomerList = Loadable(lazy(() => import('pages/customers/CustomerList')));
const CustomerDetails = Loadable(lazy(() => import('pages/customers/CustomerDetails')));
const SlaManagement = Loadable(lazy(() => import('pages/slaManagement/SlaManagement')));
const Approvers = Loadable(lazy(() => import('pages/Approvers')));
const ApprovalGroups = Loadable(lazy(() => import('pages/approvalGroups/ApprovalGroups')));
const PriceModifiers = Loadable(lazy(() => import('pages/priceModifiers/PriceModifiers')));
const ApprovalFlow = Loadable(lazy(() => import('pages/approvalFlow/ApprovalFlow')));
// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const getMainRoutes = () => {
    const MainRoutes = {
        path: '/',
        element: (
            <MainLayout />
        ),
        children: []
    };
    
    // application routes
    const ChildrenRoutes = [
        {
            path: '*',
            element: <Navigate to="/dashboard" />
        },
        {
            path: '/',
            element: <Navigate to="/dashboard" />
        },
        {
            path: '/dashboard',
            element: <DashboardDefault />,
            index: true
        }
        ,
        {
            path: '/quotes/:filterid',
            element: <Quotes/>
        }
        ,
        {
            path: '/quotes',
            element: <QuotesUnfiltered/>
        }
        ,
        {
            path: '/quote/:id',
            element: <QuoteDetail/>
        }
    ];
    
    // application routes conditioned by permissions
    if (arePermissionGranted('view_rules')) {
        ChildrenRoutes.push({
            path: '/rules',
            element: <Rules />
        });
    }

    if (arePermissionGranted('view_price_lists')) {
        ChildrenRoutes.push({
            path: '/price-lists',
            element: <PriceLists />
        });
        ChildrenRoutes.push({
            path: '/price-list/:id',
            element: <PriceListDetails />
        });
    }

    if (arePermissionGranted('view_price_modifiers')) {
        ChildrenRoutes.push({
            path: '/price-modifiers',
            element: <PriceModifiers />
        });
    }

    ChildrenRoutes.push({
        path: '/users',
        element: <Users />
    });
    
    if (arePermissionGranted('view_approvers')) {
        ChildrenRoutes.push({
            path: '/approvers',
            element: <Approvers />
        });
    }

    if (arePermissionGranted('view_approval_groups')) {
        ChildrenRoutes.push({
            path: '/approval-groups',
            element: <ApprovalGroups />
        });
    }
    
    
    ChildrenRoutes.push({
        path: '/subcontractors',
        element: <Subcontractors />
    });
    
    ChildrenRoutes.push({
        path: '/subcontractor/:id',
        element: <Subcontractor />
    });
    
    ChildrenRoutes.push({
        path: '/items',
        element: <Items />
    });
    
    ChildrenRoutes.push({
        path: '/item/:id',
        element: <Item />
    });
    
    if (arePermissionGranted('view_customers')) {
        ChildrenRoutes.push({
            path: '/customers',
            element: <CustomerList />
        });
    
        ChildrenRoutes.push({
            path: '/customer/:id',
            element: <CustomerDetails />
        });
    }
    if (arePermissionGranted('view_sla_management')) {
        ChildrenRoutes.push({
            path: '/sla-management',
            element: <SlaManagement />
        });
    }

    if (arePermissionGranted('view_approval_settings')) {
        ChildrenRoutes.push({
            path: '/approval-flow',
            element: <ApprovalFlow />
        });
    }

    MainRoutes.children = ChildrenRoutes;

    return MainRoutes;
};
export default getMainRoutes;
