import { getRequest } from "./QuoteApiService";

export async function getUserId() {
    try {
        const res = await getRequest('getUserId');
        console.log("GetUserId > axios res=", res);
        return res;
      } catch (error) {
        console.error("in services > GetUserId, Err===", error);
        return error;
      }
}