import axios from "axios";
import React from "react";
import { getRequest } from "./QuoteApiService";

export const getSubcontractorLookups = ( tenantId) => {
  const call = getRequest('GetSubcontractorLookups/' + tenantId);

  console.log("Services > getSubcontractorLookups called...");

  return new Promise((resolve, reject) => {
    try {
      axios
      .all([call])
      .then((res) => {
        console.log("getSubcontractorLookups > axios res=", res);
        resolve(res);
      })
      .catch((err) => {
        console.log("getSubcontractorLookups > axios err=", err);
        reject("Error in getSubcontractorLookups axios!");
      });
    } catch (error) {
      console.error("in services > getSubcontractorLookups, Err===", error);
      //reject(SYSTEM_ERROR);
    }
  });
  
}
