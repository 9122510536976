import axios from "axios";
import React from "react";
import { getRequest } from "./QuoteApiService";

export const getSubcontractor = (id) => {
    const call = getRequest('GetSubcontractor/' + id);

    console.log("userServices > GetSubcontractor called...");
    return new Promise((resolve, reject) => {
      try {
        axios
        .all([call])
        .then(res => {
          console.log("GetSubcontractor > axios res=", res);
          resolve(res);
        })
        .catch((err) => {
          console.log("GetSubcontractor > axios err=", err);
          reject("Error in GetSubcontractor axios!");
        });
      } catch (error) {
        console.error("in services > GetSubcontractor, Err===", error);
        //reject(SYSTEM_ERROR);
      }
    }); 
  
}
