import { postRequest } from "./QuoteApiService";

export const itemsImport = async (file) => {
  try {
    const formData = new FormData();
        formData.append('fileUpload', file);
        const config = {
            headers: {
            'content-type': 'multipart/form-data',
            },
        };
    const res = await postRequest(`ItemsImport/`, formData, config);
    console.log("ItemsImport > axios res=", res);
    return res;
  } catch (error) {
    console.error("in services > ItemsImport, Err===", error);
    return error;
  }
}