import axios from "axios";
import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";

const env = process.env;
const baseURL = process.env.REACT_APP_QUOTING_FUNCTIONAPP;
const apiKey = process.env.REACT_APP_QUOTING_FUNCTIONAPP_HOST_KEY;

export const getRequest = (requestUri, params, optionsIn) => {
    const paramsStr = parseParams(params);
    const requestUrl = baseURL + requestUri + paramsStr;

    return new Promise((resolve, reject) => {
        getHeaderOptions(optionsIn).then((headerOptions) => {
            return axios.get(requestUrl, headerOptions);
        }).then((response) => {
            resolve(response);
        }).catch((error)=> {
            console.error("Error on GET request:", error.toJSON());
            reject(error);
        });
    });
};

export const postRequest = (requestUri, body, optionsIn) => {
    const requestUrl = baseURL + requestUri;

    return new Promise((resolve, reject) => {
        getHeaderOptions(optionsIn).then((headerOptions) => {
            return axios.post(requestUrl, body, headerOptions);
        }).then((response) => {
            resolve(response);
        }).catch((error)=> {
            console.error("Error on POST request:", error.toJSON());
            reject(error);
        });
    });
}

export const putRequest = (requestUri, body, optionsIn) => {
    const requestUrl = baseURL + requestUri;

    return new Promise((resolve, reject) => {
        getHeaderOptions(optionsIn).then((headerOptions) => {
            return axios.put(requestUrl, body, headerOptions);
        }).then((response) => {
            resolve(response);
        }).catch((error)=> {
            console.error("Error on PUT request:", error.toJSON());
            reject(error);
        });
    });
}

export const deleteRequest = (requestUri, optionsIn) => {
    const requestUrl = baseURL + requestUri;

    return new Promise((resolve, reject) => {
        getHeaderOptions(optionsIn).then((headerOptions) => {
            return axios.delete(requestUrl, headerOptions);
        }).then((response) => {
            resolve(response);
        }).catch((error)=> {
            console.error("Error on DELETE request:", error.toJSON());
            reject(error);
        });
    });
}

const parseParams = (params) => {
    params = params || {};
    const paramsArray = [];

    for (const [paramKey, paramValue] of Object.entries(params)) {
        paramsArray.push(`${paramKey}=${paramValue}`);
      }

    return paramsArray.length ? '?' + paramsArray.join(',') : '';
};

const getHeaderOptions = (optionsIn) => {
    if (!optionsIn) {
        optionsIn = {};
    }

    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const tokenPromise = msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
        redirectUri: window.location.origin + '/accept-token'
    });

    return new Promise((resolve, reject) => {
        tokenPromise.then((token) => {
            if (!token || !token.accessToken) {
                reject("No token found!");
            }

            // Merge our auth headers to the provided options structure
            let headersIn = {};
            if (optionsIn.headers) {
                headersIn = optionsIn.headers;
            }
            const headers = Object.assign({}, headersIn, {
                "x-functions-key": apiKey,
                Authorization: `Bearer ${token.accessToken}`,
            });
            const options = Object.assign({}, optionsIn, { headers: headers});
            resolve(options);
        }).catch((err) =>  {
            console.error(err);
            reject('Error getting API token: ' + err);
        });
    })
}
