import getApplicationItems from './application';

// ==============================|| MENU ITEMS ||============================== //

const getMenuItems = () => {

    const application = getApplicationItems();

    return {
        items: [application ]
    }
};

export default getMenuItems;
