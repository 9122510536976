import { postRequest } from "./QuoteApiService";

export async function getAllPriceLists(body) {
    try {
        const res = await postRequest('GetPriceLists', body);
        console.log("GetPriceLists > axios res=", res);
        return res;
      } catch (error) {
        console.error("in services > GetPriceLists, Err===", error);
        return error;
      }
}