import React from "react";
import { postRequest } from "./QuoteApiService";

export const getSubcontractorQuoteHistory = (rec) => {
  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      postRequest('GetSubcontractorQuoteHistory/', rec)
      .then((res) => {
        console.log("GetSubcontractorQuoteHistory > axios res=", res);
        //const persons = res.data;
        resolve(res.data);
      })
      .catch((err) => {
        console.log("GetSubcontractorQuoteHistory > axios err=", err);
        reject("Error in getAllUsers axios!");
      });
    } catch (error) {
      console.error("in services > GetSubcontractorQuoteHistory, Err===", error);
      //reject(SYSTEM_ERROR);
    }
  });
  
}
