import axios from "axios";
import React from "react";
import { getRequest } from "./QuoteApiService";

export const getStatusesAutocomplete = (tenantId, quoteId, statustype) => {
  const call = getRequest('GetStatusesAutocomplete/' + tenantId + '/' + statustype + '/?quoteId=' + quoteId );

  console.log("userServices > GetStatusesAutocomplete called...");
  return new Promise((resolve, reject) => {
    try {
      axios
      .all([call])
      .then((res) => {
        console.log("GetStatusesAutocomplete > axios res=", res);
        resolve(res);
      })
      .catch((err) => {
        console.log("GetStatusesAutocomplete > axios err=", err);
        reject("Error in GetStatusesAutocomplete axios!");
      });
    } catch (error) {
      console.error("in services > GetStatusesAutocomplete, Err===", error);
      //reject(SYSTEM_ERROR);
    }
  });

}
