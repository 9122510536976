import axios from "axios";
import React from "react";
import { getRequest } from "./QuoteApiService";

export const setRenumberQuoteLine = (id) => {
  const quotelineCall = getRequest('SetQuoteLineRenumber/' + id);
  console.log("Services > setRenumberQuoteLine called...");

  return new Promise((resolve, reject) => {
    try {
      axios
      .all([quotelineCall])
      .then((res) => {
        console.log("setRenumberQuoteLine > axios res=", res);
        resolve(res);
      })
      .catch((err) => {
        console.log("setRenumberQuoteLine > axios err=", err);
        reject("Error in setRenumberQuoteLine axios!");
      });
    } catch (error) {
      console.error("in services > setRenumberQuoteLine, Err===", error);
    }
  });
  
}
