import React from "react";
import { postRequest } from "./QuoteApiService";

export const getAccountsTypeahead = (rec) => {
  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      postRequest('GetAccountTypeahead/', rec)
      .then((res) => {
        console.log("getAccountsTypeahead > axios res=", res);
        //const persons = res.data;
        resolve(res.data);
      })
      .catch((err) => {
        console.log("getAccountsTypeahead > axios err=", err);
        reject("Error in getAccountsTypeahead axios!");
      });
    } catch (error) {
      console.error("in services > getAccountsTypeahead, Err===", error);
      //reject(SYSTEM_ERROR);
    }
  });
  
}
