import { postRequest } from "./QuoteApiService";

export async function getPriceLinesExport(id, body) {
    try {
        const res = await postRequest('GetPriceLinesExportToExcel/' + id, body);
        console.log("GetPriceLinesExportToExcel > axios res=", res);
        return res;
      } catch (error) {
        console.error("in services > GetPriceLinesExportToExcel, Err===", error);
        return error;
      }
}