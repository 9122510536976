// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';

import ThemeCustomization from 'themes';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { useEffect } from 'react';
import { useState } from 'react';
import { fetchUserInfo } from 'services';
import { AlertProvider, SnackBarAlert } from 'components/globalAlert';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// MSAL imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";
import { loginRequest } from './authConfig';
import AcceptInvite from 'pages/AcceptInvite';

import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey('d804799f12b78f833801b3673e933ebaTz05MzI1NyxFPTE3NTEwNTIxNTgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');


// ==============================|| APP ||============================== //

const App = () => {

    const [loading, setLoading] = useState(true);
    const { instance, accounts, inProgress } = useMsal();

    useEffect(() => {
        const account = instance.getAllAccounts()[0];

        const accessTokenRequest = {
            ...loginRequest,
            account: account,
            redirectUri: window.location.origin + '/accept-token'
        };

        const checkTokenExpiration = setInterval( () => {
            instance.acquireTokenSilent(accessTokenRequest)
            .then((_) => {})
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(loginRequest);
                } else {
                    console.log(error);
                }
            });
        }, 300000);
        return () => clearInterval(checkTokenExpiration);
    }, []);

    useEffect(() => {

        if (!accounts.length || window.location.pathname === '/accept-token') {
            return;
        };

        fetchUserInfo()
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        })
    }, [accounts]);

    if (window.location.pathname === '/accept-invite') {
        return (
          <AcceptInvite />
        );
    }

    if (window.location.pathname === '/accept-token') {
        return ("");
    }

    if (loading || (!accounts.length)) {
        return (
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={loginRequest}
            >
              {'Loading...'}
            </MsalAuthenticationTemplate>
          )
    }

    return (
    <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}>
        <ThemeCustomization>
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <>
                                <AlertProvider>
                                    <Routes />
                                    <Snackbar />
                                    <SnackBarAlert />
                                </AlertProvider>
                            </>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    </MsalAuthenticationTemplate>
    );
};

export default App;
