import axios from "axios";
import React from "react";
import { deleteRequest } from "./QuoteApiService";

export const deleteQuoteLine = (id) => {
  const quotelineCall = deleteRequest('DeleteLine/' + id);
  console.log("Services > DeleteQuoteLine called...");

  return new Promise((resolve, reject) => {
    try {
      axios
      .all([quotelineCall])
      .then((res) => {
        console.log("DeleteQuoteLine > axios res=", res);
        resolve(res);
      })
      .catch((err) => {
        console.log("DeleteQuoteLine > axios err=", err);
        reject("Error in getAllUsers axios!");
      });
    } catch (error) {
      console.error("in services > DeleteQuoteLine, Err===", error);
      //reject(SYSTEM_ERROR);
    }
  });
  
}
