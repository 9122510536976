import { getRequest } from "../services";

export const fetchUserInfo = (userId) => {

    return new Promise((resolve, reject) => {
        
        /*const permissionsCache = sessionStorage.getItem('permissions');
        if (permissionsCache !== null && permissionsCache !== '') {
            resolve();
            return;
        }*/

        getRequest('GetUserInfo')
            .then((response) => {
                // set user info
                const permissions = response.data.permissions || [];
                sessionStorage.setItem('permissions', permissions.join(','));
                resolve();
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
}

export const arePermissionGranted = (permissions) => {

    const permissionsArray = Array.isArray(permissions) ? permissions : [permissions];
    const permissionsString = sessionStorage.getItem('permissions') || '';
    const userPermissionsArray = permissionsString.split(',');
    return permissionsArray.every(permission => 
        userPermissionsArray.includes(permission)
    );
}