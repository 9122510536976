import { getRequest } from "./QuoteApiService";

export async function getItemSLARatePriceLists(quoteId) {
    try {
        const res = await getRequest('GetItemSLARatePriceLists/'+quoteId);
        console.log("GetItemSLARatePriceLists > axios res=", res);
        return res;
      } catch (error) {
        console.error("in services > GetItemSLARatePriceLists, Err===", error);
        return error;
      }
}