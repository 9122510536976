import axios from "axios";
import React from "react";
import { getRequest } from "./QuoteApiService";

export const getSubcontractorsSearch = (tenantId, activeOnly, search) => {
    const call = getRequest('GetSubcontractorsSearch/' + tenantId + '/' + activeOnly + '/' + search);

    console.log("userServices > GetSubcontractorsSearch called...");
    return new Promise((resolve, reject) => {
      try {
        axios
        .all([call])
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("GetSubcontractorsSearch > axios err=", err);
          reject("Error in GetSubcontractorsSearch axios!");
        });
      } catch (error) {
        console.error("in services > GetSubcontractorsSearch, Err===", error);
        //reject(SYSTEM_ERROR);
      }
    });
  
}
