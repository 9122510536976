import axios from "axios";
import React from "react";
import { getRequest } from "./QuoteApiService";

export const getRuleEditLookups = () => {
  const call = getRequest('GetRuleEditLookups');

  return new Promise((resolve, reject) => {
    try {
      axios
      .all([call])
      .then((res) => {
        console.log("getRuleEditLookups > axios res=", res);
        resolve(res);
      })
      .catch((err) => {
        console.log("getRuleEditLookups > axios err=", err);
        reject("Error in getRuleEditLookups axios!");
      });
    } catch (error) {
      console.error("in services > getRuleEditLookups, Err===", error);
      //reject(SYSTEM_ERROR);
    }
  });
  
}
