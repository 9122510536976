import axios from "axios";
import React from "react";
import { getRequest } from "./QuoteApiService";

export const getApproverTypeAutocomplete = () => {
    const call = getRequest('ApproverTypeAutocomplete/');

    console.log("userServices > ApproverTypeAutocomplete called...");
    return new Promise((resolve, reject) => {
      try {
        axios
        .all([call])
        .then((res) => {
          console.log("ApproverTypeAutocomplete > axios res=", res);
          resolve(res);
        })
        .catch((err) => {
          console.log("ApproverTypeAutocomplete > axios err=", err);
          reject("Error in ApproverTypeAutocomplete axios!");
        });
      } catch (error) {
        console.error("in services > ApproverTypeAutocomplete, Err===", error);
        //reject(SYSTEM_ERROR);
      }
    });
  
}