import React from "react";
import { postRequest } from "./QuoteApiService";

export const quoteStatusChange = (rec) => {

    console.log("Services > SetQuoteStatus called...");
    
    //const config = { responseType: 'blob', headers: { 'x-functions-key': apiKey, 'Access-Control-Expose-Headers': '*,Authorization,x-filename', 'Accept': '*/*'} };

    return new Promise((resolve, reject) => {
      try {
        postRequest('SetQuoteStatus/', rec)
        .then((res) => {
          console.log("SetQuoteStatus > axios res=", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("SetQuoteStatus > axios err=", err);
          reject("Error in SetQuoteStatus axios!");
        });
      } catch (error) {
        console.error("in services > SetQuoteStatus, Err===", error);
      }
    });
  
}