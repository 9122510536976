import axios from "axios";
import { deleteRequest } from "./QuoteApiService";

export const deleteAddress = (id) => {
    const deleteCall = deleteRequest('DeleteAddress/' + id);
    console.log("Services > DeleteAddress called...");
  
    return new Promise((resolve, reject) => {
      try {
        axios
        .all([deleteCall])
        .then((res) => {
          console.log("DeleteAddress > axios res=", res);
          resolve(res);
        })
        .catch((err) => {
          console.log("DeleteAddress > axios err=", err);
          reject("Error in DeleteAddress axios!");
        });
      } catch (error) {
        console.error("in services > DeleteAddress, Err===", error);
        //reject(SYSTEM_ERROR);
      }
    });
    
  }