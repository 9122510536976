import { postRequest } from "./QuoteApiService";

export const setAddress = async (addressBody) => {
  try {
    const res = await postRequest('SetAddress/', addressBody);
    console.log("setAddress > axios res=", res);
    return res;
  } catch (error) {
    console.error("in services > setAddress, Err===", error);
  }
}