import { postRequest } from "./QuoteApiService";

export async function getLinesExport(id, body) {
    try {
        const res = await postRequest('GetLinesExportToExcel/' + id, body);
        console.log("GetLinesExportToExcel > axios res=", res);
        return res;
      } catch (error) {
        console.error("in services > GetLinesExportToExcel, Err===", error);
        return error;
      }
}