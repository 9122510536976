import React from "react";
import { postRequest } from "./QuoteApiService";

export const quoteHeaderUpdateFields = (rec) => {

    console.log("Services > quoteHeaderUpdateFields called...");
    
    //const config = { responseType: 'blob', headers: { 'x-functions-key': apiKey, 'Access-Control-Expose-Headers': '*,Authorization,x-filename', 'Accept': '*/*'} };

    return new Promise((resolve, reject) => {
      try {
        postRequest('SetQuoteHeaderFields/', rec)
        .then((res) => {
          console.log("SetQuoteHeaderFields > axios res=", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("SetQuoteHeaderFields > axios err=", err);
          reject("Error in SetQuoteHeaderFields axios!");
        });
      } catch (error) {
        console.error("in services > SetQuoteHeaderFields, Err===", error);
      }
    });
  
}