import { postRequest } from "./QuoteApiService";

export const setItemSLARateCompare = async (id, body) => {
  try {
    const res = await postRequest('SetItemSLARateCompareTo/'+id, body);
    console.log("SetItemSLARatesCompareTo > axios res=", res);
    return res;
  } catch (error) {
    console.error("in services > SetItemSLARatesCompareTo, Err===", error);
    return error;
  }
}