import React from "react";
import { postRequest } from "./QuoteApiService";

export const saveSubcontractor = (rec) => {

    console.log("userServices > setSubcontractor called...");
    
    return new Promise((resolve, reject) => {
      try {
        // do an SDK, DB call or API endpoint axios call here and return the promise.
        postRequest('SetSubcontractor/', rec)
        .then((res) => {
          console.log("SetSubcontractor > axios res=", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("SetSubcontractor > axios err=", err);
          reject("Error in SetSubcontractor axios!");
        });
      } catch (error) {
        console.error("in services > SetSubcontractor, Err===", error);
        //reject(SYSTEM_ERROR);
      }
    });
  
}