import axios from "axios";
import { deleteRequest } from "./QuoteApiService";

export const deleteSubcontractor = (id, userId) => {
    const deleteCall = deleteRequest('DeleteSubcontractor/' + id + '/' + userId);
    console.log("Services > deleteSubcontractor called...");
  
    return new Promise((resolve, reject) => {
      try {
        axios
        .all([deleteCall])
        .then((res) => {
          console.log("deleteSubcontractor > axios res=", res);
          resolve(res);
        })
        .catch((err) => {
          console.log("deleteSubcontractor > axios err=", err);
          reject("Error in getAllUsers axios!");
        });
      } catch (error) {
        console.error("in services > deleteSubcontractor, Err===", error);
        //reject(SYSTEM_ERROR);
      }
    });
    
  }