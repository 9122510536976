import { getRequest } from "./QuoteApiService";
import axios from 'axios';

export const priceListBatchResultsExport = (id) => {
  const call = getRequest('GetPriceListBatchExportToExcel/' + id);
  return new Promise((resolve, reject) => {
    try {
      axios
      .all([call])
      .then((res) => {
        console.log("GetPriceListBatchExportToExcel > axios res=", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("GetPriceListBatchExportToExcel > axios err=", err);
        reject("Error in GetPriceListBatchExportToExcel axios!");
      });
    } catch (error) {
      console.error("in services > GetPriceListBatchExportToExcel, Err===", error);
    }
  });

  
}