import axios from "axios";
import React from "react";
import { getRequest } from "./QuoteApiService";

export const getQuoteAndHeader = (id) => {

  //NOTE: trying to match what the original quote search returned to QuoteDetails
  const quotecall = getRequest('GetQuoteAndHeader/' + id);

    console.log("userServices > GetQuoteAndHeader called...");
    return new Promise((resolve, reject) => {
      try {
        axios
        .all([quotecall])
        .then((res) => {
          console.log("GetQuoteAndHeader > axios res=", res);
          resolve(res);
        })
        .catch((err) => {
          console.log("GetQuoteAndHeader > axios err=", err);
          reject("Error in GetQuoteAndHeader axios!");
        });
      } catch (error) {
        console.error("in services > GetQuoteAndHeader, Err===", error);
        //reject(SYSTEM_ERROR);
      }
    });
  
}
