import { getRequest } from "./QuoteApiService";
export const createPriceListBatch = async (priceListId, uploadTypeId) => {
  try {
    const res = await getRequest('CreatePriceListBatch/' + priceListId + '/' + uploadTypeId);
    console.log("createPriceListBatch > axios res=", res);
    return res;
  } catch (error) {
    console.error("in services > createPriceListBatch, Err===", error);
    return error;
  }
}