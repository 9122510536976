import axios from "axios";
import React from "react";
import { getRequest } from "./QuoteApiService";

export const getSubcontractorBidCounts = (id) => {

  const call = getRequest('GetSubcontractorBidCounts/' + id);
  console.log("Services > GetSubcontractorBidCounts called...");

  return new Promise((resolve, reject) => {
    try {
      axios
      .all([call])
      .then((res) => {
        console.log("GetSubcontractorBidCounts > axios res=", res);
        resolve(res);
      })
      .catch((err) => {
        console.log("GetSubcontractorBidCounts > axios err=", err);
        reject("Error in GetSubcontractorBidCounts axios!");
      });
    } catch (error) {
      console.error("in services > GetSubcontractorBidCounts, Err===", error);
      //reject(SYSTEM_ERROR);
    }
  });
  
}
