import axios from "axios";
import React from "react";
import { getRequest } from "./QuoteApiService";

export const getQuoteHeaderTabLookups = ( tenantId) => {
  const call = getRequest('GetQuoteHeaderTabLookups');

  console.log("Services > GetQuoteHeaderTabLookups called...");

  return new Promise((resolve, reject) => {
    try {
      axios
      .all([call])
      .then((res) => {
        console.log("GetQuoteHeaderTabLookups > axios res=", res);
        resolve(res);
      })
      .catch((err) => {
        console.log("GetQuoteHeaderTabLookups > axios err=", err);
        reject("Error in GetQuoteHeaderTabLookups axios!");
      });
    } catch (error) {
      console.error("in services > GetQuoteHeaderTabLookups, Err===", error);
    }
  });
  
}
