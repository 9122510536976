import { postRequest } from "./QuoteApiService";

export const getSubcontractorBids = async (id) => {
  try {
    const res = await postRequest('GetSubcontractorBids/'+id);
    console.log("GetSubcontractorBids > axios res=", res);
    return res;
  } catch (error) {
    console.error("in services > GetSubcontractorBids, Err===", error);
    return error;
  }
}
