import { Alert, Snackbar } from '@mui/material';
import useAlert from '../hooks/useAlert';
import { Fragment } from "react";

const SnackBarAlert = () => {
    const { message, severity, duration, isOpen, onAlertClose } = useAlert();

    return (
        <Snackbar open={isOpen}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={duration}
            onClose={onAlertClose}>

            <Alert onClose={onAlertClose} severity={severity} sx={{ width: '100%' }}>
                {message.split("\n").map((value, index) => {
                  return (
                    <Fragment key={index}>
                      {value}
                      <br />
                    </Fragment>
                  );
                })}
            </Alert>
        </Snackbar>
    );
};

export default SnackBarAlert;