// assets
import RulesIcon from '@mui/icons-material/Rule';
import TabIcon from '@mui/icons-material/Tab';
import ScienceIcon from '@mui/icons-material/Science';
import PeopleIcon from '@mui/icons-material/People';
import SubcontractorsIcon from '@mui/icons-material/PeopleOutline';
import DevicesIcon from '@mui/icons-material/Devices';
import SettingsIcon from '@mui/icons-material/Settings';
import SpeedIcon from '@mui/icons-material/Speed';
import { arePermissionGranted } from 'services';
import { PriceChange } from '@mui/icons-material';

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const getApplicationItems = () => {
    const application = {
        id: 'application',
        title: 'Application',
        type: 'group',
        children: []
    };

    const pricing = {
        id: 'pricing',
        title: 'Pricing',
        type: 'collapse',
        icon: PriceChange,
        children: []
    };

    const settings = {
        id: 'settings',
        title: 'Settings',
        type: 'collapse',
        icon: SettingsIcon,
        children: []
    };

    const settingsChildrenItems = [];

    const pricingItems = [];

    const childrenItems = [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: SpeedIcon,
            breadcrumbs: false
        },
        {
            id: 'quotes',
            title: 'Quotes',
            type: 'item',
            icon: TabIcon,
            url: '/quotes'
        }
    ];

    if (arePermissionGranted('view_rules')) {
        childrenItems.push({
            id: 'rules',
            title: 'Rules',
            type: 'item',
            icon: RulesIcon,
            url: '/rules'
        });
    }

    if (arePermissionGranted('view_price_lists')) {
        pricingItems.push({
            id: 'priceLists',
            title: 'Price Lists',
            type: 'item',
            url: '/price-lists'
        });
    }

    if (arePermissionGranted('view_price_modifiers')) {
        pricingItems.push({
            id: 'priceModifiers',
            title: 'Price Modifiers',
            type: 'item',
            url: '/price-modifiers'
        });
    }

    if (arePermissionGranted('view_user_details')) {
        settingsChildrenItems.push({
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/users'
        });
    }
    if (arePermissionGranted('view_approvers')) {
        settingsChildrenItems.push({
            id: 'approvers',
            title: 'Approvers',
            type: 'item',
            url: '/approvers'
        });
    }

    if (arePermissionGranted('view_approval_groups')) {
        settingsChildrenItems.push({
            id: 'approval-groups',
            title: 'Approval Groups',
            type: 'item',
            url: '/approval-groups'
        });
    }

    if (arePermissionGranted('view_approval_settings')) {
        settingsChildrenItems.push({
            id: 'approvalFlows',
            title: 'Approval Flow',
            type: 'item',
            url: '/approval-flow'
        });
    }    

    if (arePermissionGranted('view_subcontracting')) {
        settingsChildrenItems.push({
            id: 'subcontractors',
            title: 'Subcontractors',
            type: 'item',
            url: '/subcontractors'
        });
    }

    if (arePermissionGranted('view_customers')) {
        settingsChildrenItems.push({
            id: 'customers',
            title: 'Customers',
            type: 'item',
            url: '/customers'
        });
    }

    if (arePermissionGranted('view_sla_management')) {
        settingsChildrenItems.push({
            id: 'slaManagement',
            title: 'SLA Management',
            type: 'item',
            url: '/sla-management'
        });
    }



    if (arePermissionGranted('view_items')) {
        childrenItems.push({
            id: 'items',
            title: 'Items',
            type: 'item',
            icon: DevicesIcon,
            url: '/items'
        });
    }

    if (arePermissionGranted('view_price_modifiers') || arePermissionGranted('view_price_lists')) {
        pricing.children = pricingItems;
        childrenItems.push(pricing);

    }

    settings.children = settingsChildrenItems;
    childrenItems.push(settings);

    application.children = childrenItems;

    return application;
};

export default getApplicationItems;
