import axios from "axios";
import React from "react";
import { getRequest } from "./QuoteApiService";

export const getCountryRegions = (countryCode) => {
  const countryRegionsCall = getRequest('GetCountryRegionsAutocomplete/' + countryCode);
  console.log("Services > countryRegionsCall called...");

  return new Promise((resolve, reject) => {
    try {
      axios
      .all([countryRegionsCall])
      .then((res) => {
        console.log("countryRegionsCall > axios res=", res);
        resolve(res);
      })
      .catch((err) => {
        console.log("countryRegionsCall > axios err=", err);
        reject("Error in countryRegionsCall axios!");
      });
    } catch (error) {
      console.error("in services > countryRegionsCall, Err===", error);
      //reject(SYSTEM_ERROR);
    }
  });
  
}
