import { postRequest } from "./QuoteApiService";

export async function getDiscrepsExport(id, body) {
    try {
        const res = await postRequest('GetDiscrepsExportToExcel/' + id, body);
        console.log("GetDiscrepsExportToExcel > axios res=", res);
        return res;
      } catch (error) {
        console.error("in services > GetDiscrepsExportToExcel, Err===", error);
        return error;
      }
}