import { postRequest } from "./QuoteApiService";

export async function getItemSLARatesExport(id, body) {
    try {
        const res = await postRequest('GetItemSLARatesExportToExcel/' + id, body);
        console.log("GetItemSLARatesExportToExcel > axios res=", res);
        return res;
      } catch (error) {
        console.error("in services > GetItemSLARatesExportToExcel, Err===", error);
        return error;
      }
}