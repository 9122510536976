import { postRequest } from "./QuoteApiService";

export const countItemSLARates = async (id, body) => {
  try {
    const res = await postRequest('CountItemSLARates/'+id, body);
    console.log("CountItemSLARates > axios res=", res);
    return res;
  } catch (error) {
    console.error("in services > CountItemSLARates, Err===", error);
    return error;
  }
}