// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            width="100%" viewBox="0 0 1400 176" enableBackground={"new 0 0 1400 176"}>

                <path d="
                M 768 44.098 C 745.081 48.574, 729.570 72.600, 733.913 96.900 C 737.148 115.003, 751.808 131.187, 
                767.200 133.648 C 770.762 134.218, 771 134.475, 771 137.762 C 771 147.210, 776.325 154.837, 
                784.329 156.853 C 786.835 157.484, 793.411 158, 798.943 158 L 809 158 809 151 L 809 144 800.155 144 C 790.344 144, 
                788.100 142.822, 787.705 137.462 C 787.520 134.955, 787.943 134.565, 792 133.497 C 804.808 130.127, 814.902 121.623, 
                820.783 109.247 C 824.124 102.215, 824.499 100.566, 824.841 91.380 C 825.240 80.637, 823.855 73.557, 819.798 65.603 C 816.939 60, 
                808.871 51.792, 803.174 48.691 C 793.973 43.683, 779.680 41.817, 768 44.098 M 1121.704 45.630 C 1121.317 46.017, 1121 65.833, 
                1121 89.667 L 1121 133 1130 133 L 1139 133 1139 115 L 1139 97 1158.500 97 L 1178 97 1178 115.039 L 1178 133.078 1186.750 
                132.789 L 1195.500 132.500 1195.500 89 L 1195.500 45.500 1186.750 45.211 L 1178 44.922 1178 62.961 L 1178 81 1158.523 81 L 
                1139.046 81 1138.773 63.250 L 1138.500 45.500 1130.454 45.213 C 1126.028 45.055, 1122.091 45.242, 1121.704 45.630 M 1290.473 
                46.209 C 1290.201 46.919, 1290.095 66.625, 1290.239 90 L 1290.500 132.500 1297.250 132.796 L 1304 133.091 1304 126.968 L 1304 
                120.845 1307.259 125.118 C 1312.257 131.670, 1318.656 134.500, 1328.473 134.500 C 1335.248 134.500, 1337.393 134.061, 1342.224 
                131.689 C 1353.784 126.012, 1359.283 115.798, 1359.283 100 C 1359.283 92.588, 1358.795 89.234, 1357.065 84.738 C 1354.149 77.161, 
                1347.141 69.918, 1340.556 67.674 C 1330.472 64.238, 1318.022 66.284, 1311.481 72.453 L 1308.050 75.689 1307.775 60.594 L 
                1307.500 45.500 1299.234 45.209 C 1293.188 44.997, 1290.835 45.265, 1290.473 46.209 M 998 59 L 998 68 992.452 68 L 
                986.905 68 987.202 74.250 L 987.500 80.500 992.750 80.802 L 998 81.105 998 99.356 C 998 116.953, 998.092 117.792, 
                1000.571 122.766 C 1002.054 125.743, 1004.485 128.755, 1006.321 129.889 C 1010.271 132.331, 1020.732 134.222, 
                1028.009 133.811 L 1033.500 133.500 1033.794 126.250 L 1034.088 119 1027.494 119 C 1020.586 119, 1015.704 117.276, 
                1014.621 114.455 C 1014.280 113.565, 1014 105.690, 1014 96.954 L 1014 81.072 1023.750 80.786 L 1033.500 80.500 1033.798 
                74.250 L 1034.095 68 1024.048 68 L 1014 68 1014 59 L 1014 50 1006 50 L 998 50 998 59 M 765.882 62.793 C 745.813 73.277, 
                746.279 105.650, 766.643 115.614 C 774.037 119.231, 784.395 119.439, 791.276 116.108 C 802.582 110.635, 808.928 97.638, 
                806.989 83.925 C 804.805 68.469, 794.909 60, 779.031 60 C 772.433 60, 770.403 60.431, 765.882 62.793 M 939.942 66.007 
                C 932.176 67.323, 925.409 70.874, 920.225 76.355 C 913.496 83.470, 911.496 89.128, 911.514 101 C 911.526 109.346, 911.867 
                111.193, 914.314 116.202 C 926.804 141.767, 967.346 141.127, 979.339 115.176 C 981.098 111.370, 981.500 108.639, 981.500 
                100.500 C 981.500 91.793, 981.162 89.789, 978.891 85 C 973.937 74.559, 964.938 68.007, 952.862 66.047 C 945.874 64.914, 
                946.390 64.915, 939.942 66.007 M 1068.132 66.030 C 1061.136 67.481, 1055.371 70.793, 1050.593 76.108 C 1044.592 82.782, 
                1042.500 89.125, 1042.549 100.500 C 1042.596 111.797, 1045.176 118.939, 1051.591 125.541 C 1058.432 132.579, 1063.481 134.462, 
                1075.500 134.457 C 1084.362 134.453, 1086.074 134.152, 1090.547 131.812 C 1098.365 127.723, 1103.061 122.190, 1105.456 114.250 
                C 1106.118 112.054, 1105.951 112, 1098.527 112 C 1091.008 112, 1090.877 112.045, 1087.210 115.854 C 1084.457 118.715, 
                1082.388 119.881, 1079.188 120.376 C 1071.054 121.635, 1063.072 117.811, 1060.379 111.364 C 1057.591 104.692, 1056.488 105, 
                1083.143 105 L 1107.286 105 1106.731 96.899 C 1105.749 82.584, 1098.092 71.137, 1087.109 67.570 C 1080.866 65.542, 1073.405 
                64.937, 1068.132 66.030 M 838 89.290 C 838 105.243, 838.387 112.074, 839.544 116.540 C 842.859 129.336, 849.274 134.454, 862 
                134.454 C 870.968 134.454, 876.579 131.984, 880.405 126.354 L 882.879 122.714 883.189 127.607 L 883.500 132.500 890.250 
                132.796 L 897 133.091 897 100.546 L 897 68 888.500 68 L 880 68 880 88.550 C 880 110.811, 879.605 112.806, 874.385 116.912 C 
                869.552 120.714, 860.781 119.271, 857.264 114.096 C 855.741 111.855, 855.454 108.525, 855.165 89.750 L 854.831 68 846.415 68 
                L 838 68 838 89.290 M 1213.240 91.750 C 1213.708 116.601, 1214.103 119.092, 1218.688 126.076 C 1224.936 135.594, 
                1241.956 137.767, 1251.818 130.305 C 1253.644 128.924, 1255.669 126.614, 1256.318 125.172 C 1257.452 122.657, 1257.520 
                122.753, 1258 127.525 L 1258.500 132.500 1265.250 132.796 L 1272 133.091 1272 100.546 L 1272 68 1263.500 68 L 1255 68 1255 
                88.599 C 1255 111.378, 1254.288 114.766, 1248.915 117.544 C 1245.501 119.310, 1240.026 119.425, 1236.472 117.805 C 1231.494 
                115.537, 1231 112.987, 1231 89.550 L 1231 68 1221.896 68 L 1212.792 68 1213.240 91.750 M 940.655 81.459 C 936.177 83.055, 
                932.466 86.269, 930.212 90.500 C 928.008 94.640, 928.063 106.456, 930.306 110.698 C 935.490 120.500, 950.165 123.184, 
                958.511 115.857 C 962.017 112.777, 965.006 105.500, 964.994 100.068 C 964.976 91.703, 958.894 83.174, 951.477 81.115 C 
                946.489 79.730, 945.406 79.765, 940.655 81.459 M 1066.984 81.854 C 1063.714 83.594, 1059.053 89.968, 1059.016 92.750 C 
                1059.004 93.697, 1062.758 94, 1074.500 94 C 1091.815 94, 1092.054 93.881, 1088.005 87.241 C 1083.805 80.352, 1074.357 77.931, 
                1066.984 81.854 M 1318.684 81.449 C 1313.701 83.226, 1311.132 85.598, 1308.881 90.500 C 1306.820 94.988, 1306.402 104.287, 
                1308.064 108.685 C 1310.566 115.308, 1317.617 120, 1325.067 120 C 1334.410 120, 1341.067 113.222, 1341.810 102.953 C 1342.626 
                91.665, 1339.654 85.565, 1331.541 81.880 C 1326.756 79.706, 1323.844 79.609, 1318.684 81.449" 
                stroke="none" fill="#5c34b3" fill-rule="evenodd"/>

                <path d="M 54 20.496 C 31.850 25.488, 14.598 43.047, 9.591 65.697 C 7.546 74.950, 7.528 93.365, 
                9.556 102 C 15.144 125.789, 32.838 143.682, 55.529 148.489 C 90.377 155.870, 122.352 138.018, 
                129.393 107.250 L 130.823 101 118.380 101 C 107.405 101, 105.873 101.206, 105.390 102.750 C 103.641 108.346, 
                100.266 114.309, 97.110 117.381 C 83.607 130.525, 56.029 129.737, 43.481 115.848 C 35.637 107.164, 32.441 96.065, 
                33.206 80.164 C 33.612 71.741, 34.149 69.335, 37.015 63.118 C 41.120 54.213, 48.458 47.044, 56.238 44.339 C 63.990 
                41.645, 77.721 41.881, 85.177 44.838 C 94.192 48.413, 102.909 58.653, 102.978 65.750 C 103 67.962, 103.210 68, 115.500 
                68 L 128 68 128 65.105 C 128 60.114, 124.188 49.598, 120.143 43.428 C 109.699 27.499, 94.895 20.026, 72.500 19.378 C 
                64.412 19.144, 58.381 19.509, 54 20.496 M 536 84.500 L 536 147.062 548.250 146.781 L 560.500 146.500 560.500 84.500 L 
                560.500 22.500 548.250 22.219 L 536 21.938 536 84.500 M 579.676 22.657 C 579.304 23.029, 579 27.983, 579 33.667 L 579 
                44 597 44 L 615 44 615 95.500 L 615 147 627.500 147 L 640 147 640 95.500 L 640 44 658.034 44 L 676.068 44 675.784 33.250 
                L 675.500 22.500 627.926 22.240 C 601.761 22.098, 580.048 22.285, 579.676 22.657 M 179.272 52.437 C 160.742 56.927, 
                146.313 71.677, 143.080 89.434 C 136.619 124.922, 160.137 151.695, 196 149.679 C 211.301 148.818, 222.466 143.837, 
                231.423 133.875 C 239.485 124.908, 242.969 114.832, 242.988 100.432 C 243.020 76.195, 229.499 58.426, 206.540 52.530 
                C 199.462 50.713, 186.569 50.669, 179.272 52.437 M 386.698 52.096 C 370.183 55.982, 356.151 70.885, 352.999 87.886 C 
                347.491 117.590, 362.284 143.519, 387.799 148.885 C 399.760 151.401, 413.429 149.613, 423.300 144.240 C 432.260 
                139.364, 439.904 129.729, 441.620 121.151 L 442.250 118 431.375 118.038 L 420.500 118.076 417.887 121.859 C 414.753 
                126.396, 410.221 128.721, 402.819 129.591 C 389.227 131.189, 378.825 123.606, 375.602 109.750 L 374.962 107 409.481 
                107 L 444 107 444 98.894 C 444 75.218, 431.797 57.829, 411.465 52.533 C 405.101 50.875, 392.806 50.658, 386.698 52.096
                 M 502.332 54.851 C 492.876 58.119, 486.741 64.759, 483.451 75.286 C 482.205 79.272, 482.130 78.811, 482.067 66.750 L 
                 482 54 472.500 54 L 463 54 463 100.500 L 463 147 474.930 147 L 486.861 147 487.180 118.750 C 487.485 91.799, 487.602 
                 90.309, 489.721 86.347 C 494.190 77.989, 502.050 74, 514.049 74 L 519 74 519 63.500 L 519 53 513.250 53.033 C 510.087 
                 53.051, 505.175 53.869, 502.332 54.851 M 251.024 54.750 C 251.038 55.163, 257.475 75.975, 265.330 101 L 279.611 
                 146.500 298.729 146.771 L 317.846 147.043 319.354 141.771 C 320.183 138.872, 325.648 118.725, 331.497 97 C 337.346 
                 75.275, 342.357 56.712, 342.633 55.750 C 343.091 54.154, 342.097 54, 331.317 54.004 L 319.500 54.008 314.094 75.254 
                 C 301.797 123.577, 300.805 127, 299.092 127 C 297.813 127, 295.104 119.115, 286.548 90.500 L 275.635 54 263.317 54 
                 C 256.543 54, 251.011 54.337, 251.024 54.750 M 391 72.116 C 385.686 73.716, 381.240 77.593, 378.485 83.029 C 374.215 
                 91.457, 373.072 91, 398.419 91 L 420.839 91 419.822 87.250 C 417.241 77.728, 410.260 72.088, 400.250 71.436 C 397.088 
                 71.230, 392.925 71.536, 391 72.116 M 180.936 74.750 C 171.136 79.720, 166.698 88.164, 166.800 101.645 C 166.932 
                 119.059, 178.240 129.967, 194.922 128.773 C 210.788 127.638, 220.325 115.092, 218.684 97.514 C 217.864 88.737, 
                 215.515 83.649, 209.990 78.681 C 201.776 71.296, 190.701 69.798, 180.936 74.750" 
                 stroke="none" fill="#646464" fill-rule="evenodd"/>
        </svg>
    );
};

export default Logo;